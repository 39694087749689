.navbar {
    background-color: #3f51b5;
    padding: 1rem;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  .menu-icon {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 1rem;
  }
  
  .navbar-title {
    font-family: monospace;
    font-weight: 700;
    font-size: 5ch;
    letter-spacing: 0.1rem;
    text-decoration: none;
    color: white;
    flex-grow: 1;
    text-align: center;
  }
  
  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #3f51b5;
    z-index: 1;
    width: 20%;
    text-align: left;
  }
  
  .menu.open {
    display: flex;
  }
  
  .menu-item {
    padding: 1rem;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
  }
  
  .menu-item:hover {
    background-color: #2c387e;
  }
  
  .divider {
    height: 2px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 0.5rem 0;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    padding: 0.3rem;
    position: absolute;
    right: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .search-container:hover {
    background-color: rgba(255, 255, 255, 0.25);
  }
  
  .search-icon {
    margin-right: 0.1rem;
  }
  
  .search-container input {
    border: none;
    background: none;
    color: white;
    outline: none;
    width: 10ch;
    transition: width 0.3s ease;
    padding: 0.5rem;
  }
  
  .search-container input::placeholder {
    color: white;
  }
  
  .search-container input:focus {
    width: 20ch;
  }
  